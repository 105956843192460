import { Config } from 'src/components/context/config';
import { CustomerFormattedBlock } from './lead';

export enum SearchBy {
  Vin = 'vin',
  YMMT = 'ymmt',
}

export enum TradeinCondition {
  Excellent = 'excellent',
  Good = 'good',
  Fair = 'fair',
}

export enum TradeInPayoffType {
  Neither = 'neither',
  Loan = 'loan',
  Lease = 'lease',
}

export interface TradeinBlock {
  // Server Props
  id: string;
  updatedAt: string;
  createdAt: string;
  vin: string | null;
  year?: number;
  make?: string;
  model?: string;
  trim?: string;
  body?: string;
  color: string | null;
  mileage: number | null;
  condition: string | null;
  notes: string | null;
  payoffType: string | null;
  payoffLender: string;
  payoffAmount: number | null;
  photos?: string[];
  allowanceAmount?: number;
  equity?: number;
  cashBack?: number;
  actualCashValue?: number;
  ucgvehicleid?: string;
  minTrade?: number;
  maxTrade?: number;
  averageTrade?: number;

  ///Does not exist on server
  location?: string;
}

export type TradeinSubmitBlock = Omit<
  TradeinBlock,
  'id' | 'updatedAt' | 'createdAt' | 'minTrade' | 'maxTrade' | 'averageTrade'
>;

export interface TradeinFormattedBlock extends TradeinSubmitBlock {}

export interface TradeinFormDefaultValues extends CustomerFormattedBlock {
  id?: string;
  updatedAt?: string;
  createdAt?: string;
  vin: string;
  year: number | string;
  make: string;
  model: string;
  trim: string;
  body: string;
  color: string;
  mileage: number | string;
  condition: string;
  notes: string;
  payoffType: string;
  payoffLender: string;
  payoffAmount: number | string;
  photos: string[];
  allowanceAmount?: number;
  equity?: number;
  cashBack?: number;
  actualCashValue?: number;
  ucgvehicleid?: string;
  minTrade?: number;
  maxTrade?: number;
  averageTrade?: number;
  // Additional props
  searchBy: string;
  location: string;
}

export function returnTradeinFormDefaultValues(
  data?: any,
  config?: Config
): TradeinFormDefaultValues {
  return {
    id: data?.id ?? undefined,
    updatedAt: data?.updatedAt ?? undefined,
    createdAt: data?.createdAt ?? undefined,
    vin: data?.vin ?? '',
    year: data?.year ?? '',
    make: data?.make ?? '',
    model: data?.model ?? '',
    trim: data?.trim ?? '',
    body: data?.body ?? '',
    color: data?.color ?? '',
    mileage: data?.mileage ?? '',
    condition: data?.condition ?? '',
    notes: data?.notes ?? '',
    payoffType: data?.payoffType ?? '',
    payoffLender: data?.payoffLender ?? '',
    payoffAmount: data?.payoffAmount ?? '',
    photos: data?.photos ?? [],
    allowanceAmount: data?.allowanceAmount ?? undefined,
    equity: data?.equity ?? undefined,
    cashBack: data?.cashBack ?? undefined,
    actualCashValue: data?.actualCashValue ?? undefined,
    ucgvehicleid: data?.ucgvehicleid ?? undefined,
    minTrade: data?.minTrade ?? undefined,
    maxTrade: data?.maxTrade ?? undefined,
    averageTrade: data?.averageTrade ?? undefined,
    // Additional props
    searchBy: data?.id && !data?.vin ? SearchBy.YMMT : SearchBy.Vin, //check this
    // Only Widget: this data is unused in retailing look for saved data
    firstName: data?.firstName ?? '',
    lastName: data?.lastName ?? '',
    phone: data?.phone?.replace(/(\+1)/g, '') || '',
    email: data?.email ?? '',
    optoutCalls: data?.optoutCalls ?? config?.forms?.lead?.explicitOptIn ? true : false,
    optoutSms: data?.optoutSms ?? config?.forms?.lead?.explicitOptIn ? true : false,
    optoutEmails: data?.optoutEmails ?? config?.forms?.lead?.explicitOptIn ? true : false,
    //Multilocation
    location: data?.location ?? '',
  };
}

export interface TradeInResponseBlock {
  customerId: string;
  dealershipId: string;
  vin: string;
  ucgvehicleid: string;
  color: string;
  mileage: number | null;
  notes: string;
  condition: TradeinCondition;
  payoffType: TradeInPayoffType;
  payoffLender: string;
  payoffAmount: number | null;
  body: string;
  minTrade: number | null;
  averageTrade: number | null;
  maxTrade: number | null;
  averageRetail: number | null;
  make: string;
  model: string;
  trim: string;
  year: number;

  // Non-Server Props
  id: string; // Important: ID should be customerId, added to the response object
  photos: string[];
}

export function returnTradeinSubmitBlock(data?: any): TradeinSubmitBlock {
  return {
    vin: data?.searchBy === SearchBy.YMMT ? null : data?.vin,
    ucgvehicleid: data?.ucgvehicleid,
    color: data?.color || null,
    mileage: Number(data?.mileage) ?? null,
    notes: data?.notes || null,
    condition: data?.condition || null,
    payoffType: data?.payoffType.toString() || null,
    payoffLender:
      data?.payoffLender === '' || data?.payoffType.toString() === TradeInPayoffType.Neither
        ? 'None'
        : data?.payoffLender,
    payoffAmount:
      data?.payoffAmount === '' || data?.payoffType.toString() === TradeInPayoffType.Neither
        ? null
        : Number(data?.payoffAmount),
    photos: data?.photos?.length ? data.photos : undefined,
    body: data?.body,
  };
}
